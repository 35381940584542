'use strict';

var activateSlickSlider = function () {
	$('.Quick-links ul').slick({
  		dots: false,
  		arrows:true,
  		infinite:true,
  		slidesToShow: 6,
  		slidesToScroll: 6,
  		responsive: [
    		{
      		breakpoint: 992,
      		settings: {
        	slidesToShow: 4,
        	slidesToScroll: 1
      		}
    	},
    	{
      		breakpoint: 580,
      		settings: {
        	slidesToShow: 2,
        	slidesToScroll: 1
      		}
    	}
        ]	
	});
}

module.exports = {
 activateSlickSlider : activateSlickSlider

}